import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import EmailCaptureForm from "../components/EmailCaptureForm"
import Seo from "../components/seo"

import Layout from "../components/layout"


const PurposePage = ({ data }) => (
  <Layout>
    <Seo
      title="Purpose — Social Impact Magazine"
      keywords={[
        `Social Impact Magazine`,
        `Entrepreneurship Magazine`,
        `Entrepreneur Magazine`,
      ]}
    />
    <main className="main" role="document">
      <section className="what-is-social-impact  [ section  section--decent-highlight ]">
        <aside className="row  shell-flex-parent--end-main">
          <div className="[ column  column--xs-offset-1  column--xs-5  column--sm-offset-0  column--sm-3  hidden--desktop ]">
            <div className="margin-right--2">
              <figure className="[ impression  impression--small ]">
                <div className="[ color-overlay  color-overlay--neutral ]" />
                <div className="desaturate">
                  <StaticImage src="../images/entrepreneur-fashion.jpg" alt="Entrepreneur" placeholder="blurred" />
                </div>
              </figure>
            </div>
          </div>
        </aside>
        <div className="container">
          <header className="row  what-is-social-impact__header  margin-bottom--10">
            <div className="[ column  column--xs-6  column--lg-4 ]  shell-flex-parent--start-main">
              <div className="margin-top--20">
                <hgroup className="headlines">
                  <h4 className="[ headline  headline--uppersmall ]  margin-bottom--1">
                    What is Social Impact?
                  </h4>
                  <hr className="[ hr  hr--uppersmall ]  margin-bottom--3" />
                  <h2 className="[ headline  headline--beta ]  margin-bottom--4">
                    Changing the world through entrepreneurship
                  </h2>
                </hgroup>
                <div className="[ description  description--hero ]">
                  <p>
                    We are living in a world where we never had so many pressing
                    social and environmental challenges to face and we never had
                    that kind of access to knowledge what technology brought us.
                    These challenges inspire purpose-driven entrepreneurs to
                    create sustainable solutions.
                  </p>
                </div>
              </div>
            </div>
            <div className="[ column  column--xs-6  column--lg-2 ]  hidden--mobile">
              <div className="margin-right--2">
                <figure className="[ impression  impression--small ]">
                  <div className="[ color-overlay  color-overlay--neutral ]" />
                  <div className="desaturate">
                    <StaticImage src="../images/entrepreneur-fashion.jpg/" alt="Entrepreneur" placeholder="blurred" />
                  </div>
                </figure>
              </div>
            </div>
          </header>
          <aside className="row  what-is-social-impact__content  margin-bottom--10  shell-flex-parent--inline">
            <div className="[ column  column--xs-1  column--sm-3  column--lg-2 ]  position--relative">
              <div className="headlines--alternative">
                <h5 className="[ headline  headline--alternative ]">
                  <span className="headline--alternative__transform">
                    Key ingredients
                  </span>
                </h5>
              </div>
            </div>
            <div className="[ column  column--xs-5  column--sm-3  column--lg-2 ]">
              <div className="[ line  line--border-top ]">
                <hgroup className="headlines  position--relative  margin-top--2  margin-bottom--2">
                  <span className="[ headline  headline--alpha-column ]  night">
                    01
                  </span>
                  <h3 className="[ headline  headline--gamma-column  headline--on-top ]">
                    Taking responsibility
                  </h3>
                </hgroup>
                <div className="[ description  description--small ]  margin-bottom--6">
                  <p>
                    The entrepreneurs combine the impact with a healthy
                    profitable return in their solutions. At the same time, they
                    inspire sustained businesses to include social impact in
                    their business models. In fact, every company leaves a
                    lasting mark on the world and should take responsibility to
                    make this a positive one.
                  </p>
                </div>
              </div>
            </div>
            <div className="[ column  column--xs-offset-1  column--xs-5  column--sm-offset-0  column--sm-3  column--lg-2 ]">
              <div className="[ line  line--border-top ]">
                <hgroup className="headlines  position--relative  margin-top--2  margin-bottom--2">
                  <span className="[ headline  headline--alpha-column ]  night">
                    02
                  </span>
                  <h3 className="[ headline  headline--gamma-column  headline--on-top ]">
                    Respectful leadership
                  </h3>
                </hgroup>
                <div className="[ description  description--small ]  margin-bottom--6">
                  <p>
                    Role models are crucial to decisively drive change. Through
                    thoughtful and respectful leadership, new companies and
                    established enterprises can collaborate to address the grand
                    challenges of our time. These businesses show that
                    sustainable and inclusive growth combine both profit and
                    impact.
                  </p>
                </div>
              </div>
            </div>
            <div className="[ column  column--xs-offset-1  column--xs-5  column--sm-offset-0  column--sm-3  column--lg-2 ]">
              <div className="[ line  line--border-top ]">
                <hgroup className="headlines  position--relative  margin-top--2  margin-bottom--2">
                  <span className="[ headline  headline--alpha-column ]  night">
                    03
                  </span>
                  <h3 className="[ headline  headline--gamma-column  headline--on-top ]">
                    Sustainable Innovation
                  </h3>
                </hgroup>
                <div className="[ description  description--small ]  margin-bottom--6">
                  <p>
                    This new generation of entrepreneurs seeking to understand,
                    learn from and improve global development activities. The
                    resulting products were run through careful research, where
                    they included a technical, economic and social perspective
                    so that society starts to consume and produce sustainably.
                  </p>
                </div>
              </div>
            </div>
            <div className="[ column  column--xs-offset-1  column--xs-5  column--sm-offset-0  column--sm-3  column--lg-2 ]">
              <div className="[ line  line--border-top ]">
                <hgroup className="headlines  position--relative  margin-top--2  margin-bottom--2">
                  <span className="[ headline  headline--alpha-column ]  night">
                    04
                  </span>
                  <h3 className="[ headline  headline--gamma-column  headline--on-top ]">
                    Connections and Exchange
                  </h3>
                </hgroup>
                <div className="[ description  description--small ]  margin-bottom--6">
                  <p>
                    This magazine strives to inspire and connect these
                    changemakers and social entrepreneurs to overcome their
                    struggles and continue to strive for their goals because
                    every bit of innovation could be responsible for a
                    long-lasting future. We are dedicated to bringing
                    information and people together.
                  </p>
                </div>
              </div>
            </div>
            <div className="[ column  column--xs-offset-1  column--xs-5  column--sm-offset-0  column--sm-3  column--lg-2 ]  shell-flex-child--align-bottom  impression--column-fix">
              <div className="margin-right--2">
                <figure className="[ impression  impression--small ]">
                  <div className="[ color-overlay  color-overlay--neutral ]" />
                  <div className="desaturate">
                    <StaticImage src="../images/entrepreneur-with-hat.jpg/" alt="Entrepreneur" placeholder="blurred" />
                  </div>
                </figure>
              </div>
            </div>
          </aside>
        </div>
      </section>
      <section
        idName="email"
        className="email-updates  [ section  section--call-to-action ]"
      >
        <div className="container">
          <div className="row">
            <div className="[ column  column--xs-6  column--sm-2  column--lg-2 ]">
              <div className="margin-right--2  position--relative">
                <figure className="[ impression  impression--small ]">
                  <div className="[ color-overlay  color-overlay--call-to-action ]" />
                  <div className="desaturate">
                    <StaticImage src="../images/man-lay-wall.jpg/" alt="Man lay on wall" placeholder="blurred" />
                  </div>
                </figure>
                <div className="headlines--alternative  absolute-margin-top--2  hidden--mobile">
                  <h5 className="[ headline  headline--alternative ]">
                    <span className="headline--alternative__transform">
                      Subscribe
                    </span>
                  </h5>
                </div>
              </div>
            </div>
            <div className="[ column  column--xs-6  column--sm-4  column--lg-4 ]  margin-bottom--10">
              <hgroup className="headlines  margin-top--20  margin-bottom--4">
                <h2 className="[ headline  headline--beta ]">
                  A thoughtful weekly newsletter helping you feel inspired, learn new traits, and think from new perspectives
                </h2>
              </hgroup>
              <EmailCaptureForm className="email-updates-form  [ newsletter-signup  newsletter-signup--large ]  width--100  margin-bottom--0" />
            </div>
          </div>
        </div>
      </section>
    </main>
  </Layout>
)

export default PurposePage
